import React from "react";
import LogoutImg from "../../resources/images/logout.png"

const LeaveRoomButton = () => {
  const handleRoomDisconnection = () => {
    const siteUrl = window.location.origin;
    window.location.href = siteUrl;
  };

  return (
    <div className="video_button_container">
      <img class="video_button_image fa fa-vedio"
        src={LogoutImg}
        onClick={handleRoomDisconnection}
      />
      {/* <button onClick={handleRoomDisconnection}>
        <img src="../resources/images/logout.png"></img>
      </button> */}
    </div>
  );
};

export default LeaveRoomButton;
